import React, {useEffect, useState} from "react";
import {StyleSheet, SafeAreaView, Text, View, Image, FlatList, TouchableOpacity, Platform} from "react-native";
import {courseApi} from "../api/courseApi";
import moment from "moment-timezone";
import {palette} from "../style/palette";
import {createStackNavigator} from "@react-navigation/stack";
import {webp} from "../config";
import {headerBarOptions} from "../style/commonStyle";
import {log} from "../logger/logger";
import {MaterialCommunityIcons, MaterialIcons} from '@expo/vector-icons';
import {getFirstName, userSession} from "../api/userSession";
import {userApi} from "../api/userApi";
import history from '../history/history';
import OncomingIcon from '../../assets/oncoming.svg';
import HistoryIcon from '../../assets/history.svg';
import {Spinner} from "../components/spinner";
import {openLink} from "../login/openExternalLink";

const Stack = createStackNavigator();

export function MeetingsScreen({navigation}) {
  return (
    <Stack.Navigator
      screenOptions={headerBarOptions}
    >
      <Stack.Screen name="Classes"
                    component={Meetings}
                    options={{
                      header: () => <MeetingsHeader title="Classes" style={headerBarOptions} navigation={navigation}/>
                    }}
      />
    </Stack.Navigator>
  );
}

let userName = "";

function MeetingsHeader({title, style, navigation}) {
  const [activeUser, setActiveUser] = useState(null);

  useEffect(() => {
    return navigation.addListener('focus', fetchActiveUser);
  }, []);

  async function fetchActiveUser() {
    const activeUser = await userApi.getUser();
    const primaryUserId = await userSession.getUserId();

    if (activeUser.relationships?.filter(r => r.type === "parentOf").length > 0 || primaryUserId !== activeUser._id) {
      setActiveUser(activeUser);
    }
  }

  return (
    <View style={{...style.headerStyle, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 16}}>
      <Text style={style.headerTitleStyle}>
        {title}
      </Text>
      {activeUser &&
        <TouchableOpacity onPress={() => {history().push('AccountPicker')}} style={{flexDirection: "row", alignItems: "center", marginTop: 20}}>
          <Text style={styles.activeUserText}>{getFirstName(activeUser.name)}</Text>
          <View style={styles.switchUserContainer}>
            <MaterialCommunityIcons name="account-convert" size={24}/>
          </View>
        </TouchableOpacity>
      }
    </View>
  );
}

export function Meetings(props) {
  const [timeline, setTimeline] = useState([]);
  const [pastTimeline, setPastTimeline] = useState([]);
  const [noOfFutureMeetings, setNoOfFutureMeetings] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [coursesRunningOutOfHours, setCoursesRunningOutOfHours] = useState([]);
  const [isShowingHistory, setShowingHistory] = useState(false);

  useEffect(() => {
    return props.navigation.addListener('focus', refreshListItems); //this should load the meetings on first visit too, since the focus is gained after rendering
  }, []);

  useEffect(() => {
    retrieveUserName();
  });

  async function retrieveUserName() {
    userName = getFirstName(await userSession.getActiveUserName()) || "";
  }

  async function refreshListItems() {
    const byStartTime = (m1, m2) => m1.startTime > m2.startTime ? 1 : -1;

    if (isRefreshing) return;
    setIsRefreshing(true);
    try {
      const meetingsInfo = await courseApi.getMeetings();
      if (!meetingsInfo) return;
      const {futureMeetings, spentMeetings} = meetingsInfo;
      setPastTimeline(spentMeetings.sort(byStartTime));

      const coursesHistories = await courseApi.getCourseHistories();
      const hoursOfCreditsLeft = [];
      const coursesRunningOutOfHours = [];
      const futureMeetingsByCourseId = {};

      Object.entries(coursesHistories).map(([courseId, courseHistory]) => {
        const courseName = courseHistory.name;
        const unscheduledHours = courseHistory.unscheduledHours;

        futureMeetingsByCourseId[courseId] = futureMeetings.filter((m) => m.courseId === courseId).sort(byStartTime);

        // set the meetings as unscheduled if they are not paid for,
        let remainingMinutes = courseHistory.remainingHours * 60;
        futureMeetingsByCourseId[courseId].forEach(m => remainingMinutes >= m.durationMins ? remainingMinutes -= m.durationMins : m.unpaid = true);

        // push an element for X more hours, if unscheduled hours are remaining
        const noOfFutureMeetings = futureMeetingsByCourseId[courseId].length;
        if (courseHistory.unscheduledHours > 0) {
          const _id = `${courseId} unscheduled hrs`;
          let label, startTime;
          if (futureMeetingsByCourseId[courseId].length > 0) {
            startTime = futureMeetingsByCourseId[courseId][noOfFutureMeetings - 1].startTime;
            label = `...and ${unscheduledHours} more hours of classes for course "${courseName}"`;
          } else {
            startTime = "2010-01-01";
            label = `You have ${unscheduledHours} hour(s) of credit left for course "${courseName}"`;
          }
          hoursOfCreditsLeft.push({_id, startTime, label});
        }

        const isLastMeetingAWeekFromNow =
          noOfFutureMeetings === 0 ||
          moment(futureMeetingsByCourseId[courseId][noOfFutureMeetings - 1].startTime).isBefore(moment().add(6, 'days'));
        if (courseHistory.remainingHours <= 2 &&
            courseHistory.paidHours > 0 &&
            !courseHistory.wontContinue &&
            isLastMeetingAWeekFromNow) {
          coursesRunningOutOfHours.push({courseId, courseName});
        }
      });
      setCoursesRunningOutOfHours(coursesRunningOutOfHours);
      setNoOfFutureMeetings(futureMeetings.length);
      setTimeline([...hoursOfCreditsLeft, ...futureMeetings].sort(byStartTime));
    } catch (e) {
      log.error("Failed to retrieve meetings", e);
    }
    setIsRefreshing(false);
  }

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.switchMeetingsContainer}>
        <SwitchMeetingButton title="Oncoming"
                             icon={<OncomingIcon width={24} height={24} fill={isShowingHistory ? palette.$accent1 : "#fff"}/>}
                             isSelected={!isShowingHistory}
                             onPress={() => setShowingHistory(false)}/>
        <SwitchMeetingButton title="History"
                             icon={<HistoryIcon width={24} height={24} fill={isShowingHistory ? "#fff" : palette.$accent1}/>}
                             isSelected={isShowingHistory}
                             onPress={() => setShowingHistory(true)}/>
      </View>
      {isRefreshing ?
        <View><Spinner color={palette.$accent1Shade2}/></View> :
        <>
          {!isShowingHistory &&
            coursesRunningOutOfHours.map((course) => (
              <TouchableOpacity style={styles.runningOutOfHoursContainer} key={course.courseId} onPress={() => renewCourse(course.courseId)}>
                <View style={styles.renewalIcon}>
                  <MaterialCommunityIcons name="clock-time-three-outline" size={25} color={palette.$accent1Shade2} />
                </View>
                <Text style={styles.runningOutOfHoursText}>
                  You are <Text style={{fontWeight: 'bold'}}>running out</Text> of class hours for the course <Text style={{fontWeight: 'bold'}}>"{course.courseName}"</Text>!
                </Text>
                <MaterialIcons style={{marginLeft: "auto"}} name="arrow-forward-ios" size={30} color={palette.$red} />
              </TouchableOpacity>
            ))
          }
          <View>
            {!isShowingHistory && noOfFutureMeetings === 0 &&
              <View style={styles.noMeetings}><Text style={styles.noMeetingsText}>No classes scheduled at the moment</Text></View>
            }
            <FlatList
              data={isShowingHistory ? pastTimeline : timeline}
              keyExtractor={m => m._id}
              refreshing={isRefreshing}
              onRefresh={refreshListItems}
              renderItem={renderItem}/>
          </View>
        </>
      }
    </SafeAreaView>
  );
}

function SwitchMeetingButton({title, icon, isSelected, onPress}) {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={[styles.switchMeetingsButton, isSelected && styles.meetingsButtonSelected]}>
        {icon}
        <Text style={[styles.switchMeetingsText, isSelected && styles.switchMeetingsTextSelected]}>{title}</Text>
      </View>
    </TouchableOpacity>
  )
}

async function renewCourse(courseId) {
  const renewalLink = await courseApi.createPaymentLink(courseId);
  openLink(renewalLink);
}

function renderItem({item}) {
  const teacher = item.teacher;
  const startDate = moment(item.startTime);
  const duration = item.durationMins;
  const meetingIsNow = shouldShowStartButton(item);
  return (
    <View style={[styles.meetingContainer]}>
      <TimeLine active={item.label}/>
      {item.label ?
        <Text style={styles.unscheduledHoursText}>{item.label}</Text> :
        <>
          <View style={[styles.dateDetailsContainer, item.unpaid && styles.unpaid]}>
            <Text style={styles.dateDD}>{startDate.format('DD')}</Text>
            <Text style={styles.dateMMMYY}>{startDate.format('MMM YY')}</Text>
          </View>

          <View style={[styles.meetingDetailsContainer, item.unpaid && styles.unpaid]}>
            <View style={styles.meetingDetailsSubContainer}>
              <View style={{flex: 1}}>
                <Text style={styles.weekday}>{meetingIsNow ? "now" : startDate.format('dddd')}</Text>
                <Text style={styles.duration}>
                  {!meetingIsNow && `${startDate.format('kk:mm')} - ${startDate.add(duration, 'minute').format('kk:mm')}`}
                </Text>
              </View>
              <View style={styles.teacher}>
                <View style={styles.teacherImageContainer}>
                  <Image
                    style={styles.teacherImage}
                    source={{
                      uri: (teacher && facesUrl(teacher.avatar)),
                    }}
                  />
                </View>
                <Text style={styles.teacherName}>{teacher?.name?.split(' ')[0]}</Text>
              </View>
            </View>
          </View>
          {meetingIsNow &&
            <TouchableOpacity onPress={() => startCall(item)} style={styles.joinMeeting}>
              <View style={styles.joinClassButton}>
                <MaterialCommunityIcons name="video" size={24} color={"white"}/>
              </View>
            </TouchableOpacity>
          }
        </>
      }
    </View>
  )
  async function startCall(meeting) {
    const name = await userSession.getName();
    const token = await userSession.getToken();
    const link = `https://call.cerah.co/${meeting._id}?courseId=${meeting.courseId}&token=${token}&name=${name}`;
    if (Platform.OS === "web")
      window.open(link, '_blank')
    else
      openLink(link);
  }

  function shouldShowStartButton(m) {
    if (!m.startTime || m.unpaid) return false;
    const timeToStart = moment(m.startTime).diff(moment(), 'minutes');
    return m.state === "PLANNED" && timeToStart < 60 && timeToStart > -m.durationMins;
  }
}

function TimeLine({active}) {
  return (
    <View style={styles.timelineContainer}>
      <View style={styles.dottedLine}/>
      <View style={[styles.timelineCircleIcon, active && styles.timelineCircleActive]}/>
    </View>
  )
}

export function facesUrl(avatarUrl) {
  if (!avatarUrl) return null;
  const trailingPath = /\/[^\/]*$/.exec(avatarUrl)[0];
  return avatarUrl.slice(0, -trailingPath.length) + '/faces' + trailingPath + (webp ? '.webp' : '');
}

const meetingContainerHeight = 75;
const avatarMargin = 12;
const avatarSize = meetingContainerHeight - (avatarMargin * 2.7);
const textColor = palette.$accent1Shade2;

const styles = StyleSheet.create({
  switchUserContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 10,
    borderRadius: 24,
    shadowColor: '#000',
    shadowOffset: {width: 1, height: 1},
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 2,
  },
  activeUserText: {
    marginRight: 15,
    fontSize: 16
  },
  switchMeetingsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 20,
    marginLeft: 20,
  },
  switchMeetingsButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 20,
    marginRight: 10,
    marginLeft: 10
  },
  meetingsButtonSelected: {
    backgroundColor: palette.$accent1Shade2
  },
  switchMeetingsText: {
    color: palette.$accent1,
    marginLeft: 5,
    fontWeight: 'bold',
    marginRight: 8
  },
  switchMeetingsTextSelected: {
    color: 'white'
  },
  timelineContainer: {
    marginTop: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  joinClassButton: {
    flexDirection: "row",
    alignItems: "center"
  },
  dottedLine: {
    position: "absolute",
    zIndex: -1,
    height: "120%",
    width: 1,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: palette.$grey,
    borderRadius: 1
  },
  container: {
    flex: 1,
    backgroundColor: "white",
  },
  runningOutOfHoursContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 20,
    padding: 20,
    borderRadius: 20,
    marginBottom: 10,
    backgroundColor: palette.$lightRed
  },
  runningOutOfHoursText: {
    color: palette.$red,
    flexShrink: 1,
  },
  scrollView: {},
  text: {
    fontSize: 42,
  },
  meetingContainer: {
    paddingTop: 3,
    marginHorizontal: 20,
    flexDirection: 'row',
    height: meetingContainerHeight,
    alignItems: 'center',
    marginBottom: 10,
    marginTop: 5
  },
  joinMeeting: {
    paddingVertical: 30,
    paddingHorizontal: 20,
    backgroundColor: palette.$accent1Shade2,
    height: avatarSize,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 10,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 1},
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 5
  },
  meetingDetailsContainer: {
    backgroundColor: palette.$primary,
    borderRadius: 10,
    flex: 1,
    height: '100%',
    paddingHorizontal: 20,
    justifyContent: "center",
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 1},
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 5
  },
  unpaid: {
    opacity: 0.2
  },
  dateDetailsContainer: {
    width: 85,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  dateDD: {
    fontFamily: 'metropolis-extra-bold',
    fontSize: 32,
    color: textColor
  },
  dateMMMYY: {
    fontFamily: 'metropolis-bold',
    fontSize: 16,
    letterSpacing: -1,
    color: textColor
  },
  teacher: {
    alignItems: "center"
  },
  teacherImageContainer: {
    backgroundColor: palette.$accent1Shade2,
    width: avatarSize,
    height: avatarSize,
    borderRadius: 100,
    overflow: 'hidden',
    alignSelf: "flex-end",
    paddingTop: avatarSize * 0.1,
  },
  teacherImage: {
    width: avatarSize,
    height: avatarSize,
  },
  teacherName: {
    fontFamily: 'metropolis-semi-bold',
    fontSize: 12,
    color: palette.$accent1Shade3
  },
  meetingDetailsSubContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  weekday: {
    fontFamily: 'metropolis-bold',
    color: textColor,
    alignItems: 'center',
    fontSize: 18.5
  },
  duration: {
    fontFamily: 'metropolis-bold',
    color: textColor,
    paddingTop: 2,
    fontSize: 15
  },
  timelineCircleIcon: {
    width: meetingContainerHeight * .2,
    height: meetingContainerHeight * .2,
    backgroundColor: "white",
    borderColor: palette.$accent1,
    borderRadius: 100,
    borderWidth: 2
  },
  timelineCircleActive: {
    borderColor: palette.$accent1Shade2,
    backgroundColor: palette.$accent1Shade2
  },
  noMeetings: {
    backgroundColor: palette.$accent1Tint2,
    padding: 20,
    marginHorizontal: 10,
    borderRadius: 10,
  },
  noMeetingsText: {
    fontSize: 16,
    alignSelf: 'center',
    fontWeight: 'bold',
    color: palette.$accent1Shade3,
  },
  creditsInfoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginHorizontal: 5,
    marginBottom: 20,
  },
  creditsInfo: {
    flex: 1,
    color: palette.$accent1Shade2,
    fontSize: 15,
    fontWeight: 'bold',
    backgroundColor: palette.$accent1Tint2,
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 20,
    flexShrink: 1,
    marginHorizontal: 5,
  },
  unscheduledHoursTimelineContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginHorizontal: 20,
    height: 70,
    alignItems: 'center'
  },
  unscheduledHoursText: {
    marginLeft: 20,
    fontSize: 16,
    fontWeight: 'bold',
    color: palette.$accent1Shade2
  },
  creditLeftText: {
    marginTop: 20,
    alignSelf: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    color: palette.$accent1Shade2
  },
  renewalIcon: {
    padding: 5,
    borderRadius: 20,
    backgroundColor: 'white',
    marginRight: 15
  }
});
