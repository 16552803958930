import storage from "@react-native-async-storage/async-storage";
import {userSession} from "../api/userSession";
import {quizApi} from "../api/quizApi";
import {log} from "../logger/logger";
import {userApi} from "../api/userApi";
import {courseApi} from "../api/courseApi";

export async function calculateInitialNavigationRoute() {
  if (!await userSession.getToken()) {
    return {route: "OtpLogin"};
  }

  if (await isUserProfileIncomplete()) {
    return {route: "UserDetails"};
  }

  const notFirstAppRun = "cerah.notFirstRun";

  if (await storage.getItem(notFirstAppRun) === "true") {
    return {route: "Home"};
  }
  
  await storage.setItem(notFirstAppRun, "true");

  const meetingsInfo = await courseApi.getMeetings();
  
  if (meetingsInfo) {
    const {futureMeetings, spentMeetings} = meetingsInfo;
    if (futureMeetings?.length !== 0 || spentMeetings?.length !== 0) {
      return {route: "Home"};
    }
  }

  try {
    const levelAttempt = await quizApi.getLatestLevelAttempt();
    if (!levelAttempt) {
      return {route: "Home", params: {screen: "Test"}};
    }
  } catch (e) {
    const userId = await userSession.getUserId();
    log.error(`Unable to get the latest level attempt for userId: ${userId}`);
  }

  return {route: "Home", params: {screen: "Notifications"}};
}

async function isUserProfileIncomplete() {
  const user = await userApi.getUser();
  return (!user.name || (user.name && user.name.includes("Provisional Account")));
}
