import storage from '@react-native-async-storage/async-storage';
import jwtDecode from 'jwt-decode';
import {pushNotifications} from "../expo/push/pushNotifications";
import {userApi} from "./userApi";
import {analytics} from "../analytics/analytics";
import {log} from "../logger/logger";
import {chatSession} from "cerah-chat";
import {chatApi} from "./chatApi";
import {featureChecker} from "../featureEnabling/featureEnabling";

const accessTokenKey = 'cerah.accessToken';
const refreshTokenKey = 'cerah.rToken';
const userKey = 'cerah.userId';
const privacyWarningKey = 'cerah.privacyWarningAccepted';
const accessTokenExpiryKey = 'cerah.jwtExpiry';
const mustChangePwdKey = 'cerah.mustChangePwd';
const activeUserKey = 'cerah.activeUser';
const relationsTokensKey = "cerah.relatedUsersToken";
const userNameKey = "cerah.userName";


export const userSession = {
  userChanged: async (token) => {
    if (token.mustChangePwd) {
      await storage.removeItem(accessTokenKey);
      await storage.removeItem(accessTokenExpiryKey);
      await storage.setItem(userKey, token.userId);
      await storage.setItem(mustChangePwdKey, "true");
      return;
    }
    const payload = jwtDecode(token.accessToken);
    await storage.setItem(userKey, payload.userId);
    await storage.setItem(accessTokenExpiryKey, payload.exp.toString());
    await storage.setItem(accessTokenKey, token.accessToken);
    await storage.setItem(refreshTokenKey, token.refreshToken);
    await storage.setItem(userNameKey, token.name);
    await storage.setItem(mustChangePwdKey, "false");
    await storage.setItem(activeUserKey, payload.userId);
    if (payload.relatedUsersTokens)
      await storage.setItem(relationsTokensKey, JSON.stringify(payload.relatedUsersTokens));
    else
      await storage.removeItem(relationsTokensKey);

    try {
      await pushNotifications.init();
      const roomId = await chatApi.getSupportRoomId();
      await chatSession.login(roomId, await userSession.getPrimaryUserToken(), (msg, extra) => log.error(msg, extra));
      await analytics.changeUserId(payload.userId);
    } catch (e) {
      log.error("Failed while initializing notifications, chat session, and analytics on userId change", e);
    }
    featureChecker.setUserId(payload.userId);
    log.changeUserId(payload.userId);
  },

  switchUser: async (userId) => {
    await storage.setItem(activeUserKey, userId);
    log.info("Switched active user", userId);
  },

  loginWithRefreshToken: async (refreshToken) => {
    await storage.setItem(refreshTokenKey, refreshToken);
    await refreshAccessToken();
  },

  clearSession: () => {
    storage.removeItem(userKey);
    storage.removeItem(accessTokenKey);
    storage.removeItem(accessTokenExpiryKey);
    storage.removeItem(refreshTokenKey);
    storage.removeItem(mustChangePwdKey);
    storage.removeItem(activeUserKey);
    storage.removeItem(relationsTokensKey);
  },

  getUserId: async () => {
    return storage.getItem(userKey);
  },

  getPrimaryUserName: async () => {
    return storage.getItem(userNameKey);
  },

  getActiveUserName: async () => {
    const activeUserId = await userSession.getActiveUserId();
    const primaryUserId = await userSession.getUserId();
    if (activeUserId && primaryUserId && activeUserId !== primaryUserId) {
      const relationsTokens = await userSession.getRelationsTokens();
      return relationsTokens[activeUserId].name;
    } else {
      return userSession.getPrimaryUserName();
    }
  },

  getActiveUserId: async () => {
    const activeUserId = await storage.getItem(activeUserKey);
    if (activeUserId) return activeUserId;
    return userSession.getUserId();
  },

  getRelationsTokens: async () => {
    const value = await storage.getItem(relationsTokensKey);
    if (!value || value === "undefined") return {};
    return JSON.parse(value);
  },

  getToken: async () => {
    const primaryUserId = await userSession.getUserId();
    const activeUserId = await userSession.getActiveUserId();
    const relationsTokens = await userSession.getRelationsTokens();

    if (activeUserId && primaryUserId && (activeUserId !== primaryUserId) && relationsTokens) {
      const payload = jwtDecode(relationsTokens[activeUserId].accessToken);
      if (payload.exp < Date.now() / 1000) {
        await refreshAccessToken();
        await storage.setItem(activeUserKey, activeUserId);
      }
      return relationsTokens[activeUserId].accessToken;
    }

    if (await userSession.getAccessTokenExpiry() < Date.now() / 1000) {
      await refreshAccessToken();
    }
    return storage.getItem(accessTokenKey);
  },

  getPrimaryUserToken: async () => {
    if (await userSession.getAccessTokenExpiry() < Date.now() / 1000) {
      await refreshAccessToken();
    }
    return storage.getItem(accessTokenKey);
  },

  getAccessTokenExpiry: async () => {
    return parseInt(await storage.getItem(accessTokenExpiryKey));
  },

  getRefreshToken: async () => {
    return storage.getItem(refreshTokenKey);
  },

  isMustChangePwd: async () => {
    return false; // There's no facility in the app to change the password anyway, so there's no point
    // return ((await storage.getItem(mustChangePwdKey)) === "true");
  },

  updateName: async (name) => {
    return storage.setItem(userNameKey, name);
  },
  
  getName: async (name) => {
    return storage.getItem(userNameKey, name);
  },
  
  acceptPrivacyWarning: async () => {
    return storage.setItem(privacyWarningKey, "true");
  },
  
  isPrivacyWarningAccepted: async () => {
    return Boolean(await storage.getItem(privacyWarningKey));
  }
};

async function refreshAccessToken() {
  if (await userSession.getRefreshToken()) {  //No point in trying to refresh the access token without a refresh token
    try {
      const newToken = await userApi.refreshToken();
      await userSession.userChanged(newToken);
    } catch (e) {
      userSession.clearSession();
    }
  }
}

export function getFirstName(name) {
  const names = name.split(' ');
  if (names.length > 1) {
    if (names[0] === "I" || names[0] === "Ni") {   //Balinese names in the form of "I Putu", "Ni Gede" etc.
      return `${names[0]} ${names[1]}${names[2] ? ` ${names[2]}` : ""}`;
    }
    if (names[0] === "M" || names[0] === "Ma") {   //First name is initial or a short for Maria
      return `${names[0]} ${names[1]}`;
    }
    if (names[0] === "Dr" || names[0] === "Mr" || names[0] === "Ms") {
      return `${names[0]} ${names[1]}`;
    }
  }
  return names[0];
}
